import dayjs from 'dayjs'
import { Product } from 'etc/storefront-types'

export const sortProducts = (products: Product[], sortBy?: CatalogSortItem) => {
	switch (sortBy) {
		case 'BEST_SELLING':
			return products
		case 'NEW':
			return products.sort((a, b) => (dayjs(a.createdAt).isBefore(b.createdAt) ? 1 : -1))
		case 'PRICE_ASC':
			return products.sort((a, b) => {
				const minA = parseFloat(a.priceRange.minVariantPrice.amount)
				const minB = parseFloat(b.priceRange.minVariantPrice.amount)
				return minA > minB ? 1 : -1
			})
		case 'PRICE_DESC':
			return products.sort((a, b) => {
				const minA = parseFloat(a.priceRange.minVariantPrice.amount)
				const minB = parseFloat(b.priceRange.minVariantPrice.amount)
				return minA < minB ? 1 : -1
			})
		case 'SALE':
			return products
				.filter(
					product =>
						parseFloat(product.compareAtPriceRange.minVariantPrice.amount) >
						parseFloat(product.priceRange.minVariantPrice.amount)
				)
				.sort((a, b) => {
					const minA = parseFloat(a.priceRange.minVariantPrice.amount)
					const minB = parseFloat(b.priceRange.minVariantPrice.amount)
					return minA > minB ? 1 : -1
				})
		default:
			return products
	}
}

export const filterProductsByPrice = (products: Product[], min: number, max: number) => {
	return products.filter(
		product =>
			parseFloat(product.priceRange.minVariantPrice.amount) >= min &&
			parseFloat(product.priceRange.minVariantPrice.amount) <= max
	)
}

export type CatalogSortItem = keyof typeof ITEMS

export interface CatalogPriceRange {
	min?: number
	max?: number
}

export interface CatalogFilter {
	sortBy?: CatalogSortItem | null
	priceRange?: CatalogPriceRange
}

export const ITEMS = {
	BEST_SELLING: {
		label: 'Beliebteste'
	},
	NEW: {
		label: 'Neuheiten'
	},
	PRICE_ASC: {
		label: 'Niedrigster Preis'
	},
	PRICE_DESC: {
		label: 'Höchster Preis'
	},
	SALE: {
		label: 'SALE'
	}
}
