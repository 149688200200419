import { useEffect, useState } from 'react'
import { Product, ProductConnection } from 'etc/storefront-types'
import {
	filterProductsByPrice,
	sortProducts,
	ITEMS,
	CatalogSortItem,
	CatalogFilter
} from 'services/catalog'
import { Button, Col, Divider, Dropdown, InputNumber, Menu, Row, Slider, Typography } from 'antd'
import { CloseOutlined, DownOutlined } from '@ant-design/icons'
import styles from './filter.module.less'

const { Text } = Typography

interface Props {
	products?: ProductConnection | null
	onChange: (products: Product[]) => void
}

const Filter = ({ products, onChange }: Props) => {
	const max =
		products?.edges.reduce(
			(a, c) =>
				parseInt(c.node.priceRange.maxVariantPrice.amount) > a
					? parseInt(c.node.priceRange.maxVariantPrice.amount) + 1
					: a,
			0
		) ?? 0

	const [filter, setFilter] = useState<CatalogFilter>()

	const filterProducts = ({
		sortBy = filter?.sortBy,
		priceRange = filter?.priceRange
	}: CatalogFilter) => {
		let items = products?.edges.map(edge => edge.node) ?? []

		if (priceRange) {
			const minPrice = priceRange.min ?? filter?.priceRange?.min ?? 0
			const maxPrice = priceRange.max ?? filter?.priceRange?.max ?? max

			items = filterProductsByPrice(items, minPrice, maxPrice)
		}
		if (sortBy) {
			items = sortProducts(items, sortBy)
		}

		onChange(items)
		setFilter({ priceRange, sortBy })
	}

	useEffect(() => {
		if (filter?.priceRange?.min === 0 && filter.priceRange.max === max) {
			setFilter(p => ({ sortBy: p?.sortBy }))
		}
	}, [filter?.priceRange])

	useEffect(() => {
		setFilter(undefined)
	}, [products])

	return (
		<div>
			<Row gutter={8} className={styles.container}>
				<Col>
					<Dropdown
						overlay={
							<Menu onClick={e => filterProducts({ sortBy: e.key.toString() as CatalogSortItem })}>
								{Object.entries(ITEMS).map(([key, value]) => (
									<Menu.Item key={key}>{value.label}</Menu.Item>
								))}
							</Menu>
						}
						placement='bottomLeft'
					>
						<Button onClick={() => filter?.sortBy && filterProducts({ sortBy: null })}>
							{filter?.sortBy ? ITEMS[filter.sortBy].label : 'Sortieren'}
							{filter?.sortBy ? <CloseOutlined /> : <DownOutlined />}
						</Button>
					</Dropdown>
				</Col>
				<Col>
					<Dropdown
						overlay={
							<Menu className={styles.priceRange}>
								<div className={styles.row}>
									<InputNumber
										value={filter?.priceRange?.min ?? 0}
										max={filter?.priceRange?.max ?? max}
										min={0}
										formatter={p => `${p} €`}
										onChange={min => filterProducts({ priceRange: { min: min as number } })}
										size='small'
									/>
									<Text className='regular-18 grey-7'>-</Text>
									<InputNumber
										value={filter?.priceRange?.max ?? max}
										max={max}
										min={filter?.priceRange?.min ?? 0}
										formatter={p => `${p} €`}
										onChange={max => filterProducts({ priceRange: { max: max as number } })}
										size='small'
									/>
								</div>
								<Slider
									value={[filter?.priceRange?.min ?? 0, filter?.priceRange?.max ?? max]}
									onChange={([min, max]) => filterProducts({ priceRange: { min, max } })}
									onAfterChange={() => 'yo'}
									range
									max={max}
									tipFormatter={value => `${value} €`}
									tooltipPlacement='bottom'
									className={styles.slider}
								/>
								<div
									onClick={() => filterProducts({ priceRange: { min: 0, max } })}
									className={styles.reset}
								>
									<a>
										<Text className='grey-8'>Zurücksetzen</Text>
									</a>
								</div>
							</Menu>
						}
						placement='bottomLeft'
					>
						<Button onClick={() => filterProducts({ priceRange: { min: 0, max } })}>
							{filter?.priceRange ? (
								<div>
									{filter.priceRange.max && (
										<>
											{filter.priceRange.min === 0 ? (
												filter.priceRange.max < max && `bis ${filter.priceRange.max} €`
											) : (
												<>
													{filter.priceRange.max < max &&
														`${filter.priceRange.min} - ${filter.priceRange.max} €`}
													{filter?.priceRange?.max === max && `ab ${filter.priceRange.min} €`}
												</>
											)}
										</>
									)}
									<CloseOutlined className={styles.icon} />
								</div>
							) : (
								<div>
									Preis
									<DownOutlined className={styles.icon} />
								</div>
							)}
						</Button>
					</Dropdown>
				</Col>
			</Row>
			<Divider />
		</div>
	)
}

export default Filter
