import Link from 'next/link'
import { getComparedPrices, getRangePriceString, getProductUnitPrice } from 'services/price'
import { Product } from 'etc/storefront-types'
import { Vendor } from 'etc/strapi-types'
import { Col, Row, Typography } from 'antd'
import HoverImage from './hover-image'
import styles from './product-preview.module.less'

const { Text } = Typography

interface Props {
	product: Product
	vendors: Pick<Vendor, 'slug' | 'name'>[]
	size?: 'small' | 'medium' | 'large'
	light?: boolean
}

const ProductPreview = ({ product, vendors, size = 'medium', light }: Props) => {
	const { sale } = getComparedPrices(product.compareAtPriceRange, product.priceRange)
	const unitPrice = getProductUnitPrice(product)

	return (
		<div className={styles.container}>
			<Link href={`/produkt/${product.handle}`}>
				<a>
					<HoverImage images={product.images} size={size} />
					<div className={styles.wrapper}>
						<div className={styles.row}>
							<Row justify='space-between' style={{ flexWrap: 'nowrap' }}>
								<Col className={styles.title}>
									<Text
										className={`semibold-${size === 'large' ? '16' : '14'} ${light && 'grey-1'}`}
										ellipsis
									>
										{product.title}
									</Text>
								</Col>
								{size !== 'small' && (
									<Col>
										<Text
											className={`${size === 'large' && 'regular-16'} ${
												unitPrice ? sale && styles.sale : sale && styles.crossed
											} ${light && 'grey-1'} ${styles.price}`}
										>
											{getRangePriceString(
												sale && !unitPrice ? product.compareAtPriceRange : product.priceRange
											)}
										</Text>
									</Col>
								)}
							</Row>
							<Row justify='space-between' style={{ flexWrap: 'nowrap' }}>
								<Col className={styles.name}>
									<Text
										className={`${size === 'large' && 'regular-16'} ${light ? 'grey-5' : 'grey-7'}`}
										ellipsis
									>
										{product.vendor && vendors.find(({ slug }) => slug === product.vendor)?.name}
									</Text>
								</Col>
								{size !== 'small' && (
									<Col>
										{unitPrice && (
											<Text className={`${styles.unitPrice} grey-6`}>({unitPrice})</Text>
										)}
										{!unitPrice && sale && (
											<Text className={`${size === 'large' && 'regular-16'} ${styles.sale}`}>
												{getRangePriceString(product.priceRange)}
											</Text>
										)}
									</Col>
								)}
							</Row>
							{size === 'small' && (
								<Row justify='space-between'>
									<Col>
										<Text className={`${sale && styles.crossed} ${light && 'grey-1'}`}>
											{getRangePriceString(sale ? product.compareAtPriceRange : product.priceRange)}
										</Text>
									</Col>
									<Col>
										{sale && (
											<Text className={styles.sale}>{getRangePriceString(product.priceRange)}</Text>
										)}
									</Col>
								</Row>
							)}
						</div>
					</div>
				</a>
			</Link>
		</div>
	)
}

export default ProductPreview
