import { useState } from 'react'
import { ImageConnection } from 'etc/storefront-types'
import styles from './hover-image.module.less'

interface Props {
	images: ImageConnection
	size: 'small' | 'medium' | 'large'
}

const sizes = {
	small: {
		width: 138,
		height: 184
	},
	medium: {
		width: 268,
		height: 357.333
	},
	large: {
		width: 360,
		height: 480
	}
}

const HoverImage = ({ images, size }: Props) => {
	const [hovered, setHovered] = useState(false)

	return (
		<div className={styles.container}>
			<img
				alt='Produktfoto'
				src={images.edges[0].node?.transformedSrc}
				className={`${styles.image} ${styles[size]}`}
				width={sizes[size].width}
				height={sizes[size].height}
			/>
			{images.edges[1] && (
				<div className={styles.second}>
					<img
						alt='Produktfoto'
						src={images.edges[1].node?.transformedSrc}
						className={`${styles.image} ${styles[size]} ${!hovered && styles.hide}`}
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
						width={sizes[size].width}
						height={sizes[size].height}
					/>
				</div>
			)}
		</div>
	)
}

export default HoverImage
