import { useEffect, useState } from 'react'
import Link from 'next/link'
import { useWindowSize } from 'services/responsive'
import { useQuery } from '@apollo/client'
import { strapi } from 'services/strapi'
import { VENDORS } from 'etc/queries'
import { Vendor } from 'etc/strapi-types'
import { Product, ProductConnection } from 'etc/storefront-types'
import { Button, Col, Grid, Pagination, Row, Typography } from 'antd'
import Slider from 'components/slider'
import ProductPreview from './product-preview'
import Filter from './filter'
import styles from './catalog.module.less'

const { useBreakpoint } = Grid
const { Text } = Typography

interface Props {
	products?: ProductConnection | null
	columns?: number
	horizontal?: boolean
	filter?: boolean
	light?: boolean
	moreHref?: string
	className?: string
}

const Catalog = ({
	products,
	columns = 4,
	horizontal,
	filter = true,
	light,
	moreHref,
	className
}: Props) => {
	const { data } = useQuery<{ vendors: Pick<Vendor, 'slug' | 'name' | 'minimumOrderValue'>[] }>(
		VENDORS,
		{
			client: strapi
		}
	)

	const [sorted, setSorted] = useState<Product[]>([])
	const [currentPage, setCurrentPage] = useState(1)

	const screen = useBreakpoint()
	const { width } = useWindowSize()

	if (!screen.sm) columns = 2
	else if (width < 920) columns = 3

	useEffect(() => {
		setSorted(products?.edges.map(edge => edge.node) ?? [])
		setCurrentPage(1)
	}, [products])

	useEffect(() => {
		if (!horizontal) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
		}
	}, [currentPage, horizontal])

	const columnsPerPage = 10

	const chunks: Product[][] = []

	if (products) {
		for (let i = 0; i < products.edges.length; i += columns) {
			chunks[i / columns] = sorted.slice(i, i + columns)
		}
	}

	return (
		<div className={className}>
			{horizontal ? (
				<div>
					{products && (
						<Slider
							items={[...products.edges, 'last']}
							renderItem={edge =>
								typeof edge === 'string' ? (
									moreHref && (
										<Link href={moreHref}>
											<a>
												<div className={styles.more}>
													<Text className='semibold-24 grey-9'>Du suchst nach mehr?</Text>
													<img src='/images/bg-mountain.png' alt='' className={styles.moreBg} />
													<Button type='primary' size='large' className={styles.moreButton}>
														Mehr Produkte zeigen →
													</Button>
												</div>
											</a>
										</Link>
									)
								) : (
									<ProductPreview product={edge.node} light={light} vendors={data?.vendors ?? []} />
								)
							}
							light={light}
						/>
					)}
				</div>
			) : (
				<div>
					{filter && <Filter products={products} onChange={setSorted} />}
					{chunks.length === 0 && (
						<div className={styles.empty}>
							<Text className='grey-8'>Keine Produkte</Text>
						</div>
					)}
					{chunks
						.slice((currentPage - 1) * columnsPerPage, currentPage * columnsPerPage)
						.map((chunk, index) => (
							<Row key={index} gutter={{ xs: 12, sm: 24, md: 32 }}>
								{chunk.map(product => (
									<Col key={product.handle} span={24 / columns}>
										<div className={styles.product}>
											<ProductPreview
												product={product}
												light={light}
												vendors={data?.vendors ?? []}
											/>
										</div>
									</Col>
								))}
							</Row>
						))}
					{chunks.length > columnsPerPage && (
						<Row justify='center' className={styles.pagination}>
							<Pagination
								current={currentPage}
								total={products?.edges.length}
								pageSize={columns * columnsPerPage}
								onChange={setCurrentPage}
								showSizeChanger={false}
							/>
						</Row>
					)}
				</div>
			)}
		</div>
	)
}

export default Catalog
