import { useLayoutEffect, useRef, useState } from 'react'
import { Row } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import styles from './slider.module.less'

interface Props<Item> {
	items: ReadonlyArray<Item>
	renderItem: (item: Item, index: number) => React.ReactNode
	light?: boolean
	showControls?: boolean
	smallGaps?: boolean
}

const Slider = <Item extends {}>({
	items,
	renderItem,
	light = false,
	showControls = true,
	smallGaps
}: Props<Item>) => {
	const [itemWidth, setItemWidth] = useState<number>()
	const [scrollIndex, setScrollIndex] = useState(0)

	const row = useRef<HTMLDivElement>(null)
	const itemRefs = useRef<Array<HTMLDivElement | null>>([])
	const scrollTimeout = useRef<NodeJS.Timeout>()

	const windowWidth = window.innerWidth < 1068 ? window.innerWidth : 1068
	const ratio = itemWidth ? Math.round(windowWidth / itemWidth) - 1 : 2

	useLayoutEffect(() => {
		if (itemRefs.current[0]) {
			setItemWidth(itemRefs.current[0]?.clientWidth)
		}
	}, [])

	function onScroll() {
		if (itemWidth) {
			const index = Math.round((row.current?.scrollLeft ?? 0) / itemWidth)

			if (scrollTimeout.current) {
				clearTimeout(scrollTimeout.current)
			}

			scrollTimeout.current = setTimeout(() => setScrollIndex(index >= 0 ? index : 0), 200)
		}
	}

	function scroll(direction: 'left' | 'right') {
		if (!itemWidth) return

		const noOfItems = itemRefs.current.length

		let index = Math.round((row.current?.scrollLeft ?? 0) / itemWidth)
		index += direction === 'right' ? ratio || 1 : -(ratio || 1)

		if (index < 0) index = 0

		let left: number | undefined

		if (index < noOfItems) {
			left = itemRefs.current[index]?.offsetLeft
		} else {
			left = itemRefs.current[noOfItems - 1]?.offsetLeft
		}

		if (left) {
			const offset = window.innerWidth > 1168 ? (window.innerWidth - 1168) / 2 : 0
			row.current?.scrollTo({ left: left - 24 - offset, behavior: 'smooth' })
		}
	}

	return (
		<div>
			<div ref={row} onScroll={onScroll} className={styles.wrapper}>
				<Row className={`${styles.row} ${smallGaps ? styles.smallGaps : ''}`}>
					{items.map((item, index) => (
						<div key={index} ref={el => (itemRefs.current[index] = el)}>
							{renderItem(item, index)}
						</div>
					))}
				</Row>
			</div>
			{showControls && (
				<Row justify='space-between'>
					<LeftOutlined
						onClick={() => scroll('left')}
						className={`${light && styles.light} ${styles.arrowBtn} ${
							scrollIndex === 0 && styles.arrowBtnDisabled
						}`}
					/>
					<RightOutlined
						onClick={() => scroll('right')}
						className={`${light && styles.light} ${styles.arrowBtn} ${
							itemWidth &&
							scrollIndex + Math.round(windowWidth / itemWidth) > items.length &&
							styles.arrowBtnDisabled
						}`}
					/>
				</Row>
			)}
		</div>
	)
}

export default Slider
